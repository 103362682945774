<template>
    <div>
        <div>
            <b-col class="content-header-left mb-1" cols="12" md="12">
                <b-row class="breadcrumbs-top">
                <b-col cols="12">
                    <h2 class="content-header-title float-left pr-1 mb-0">
                         Channel Partner
                     </h2>
                    <div class="breadcrumb-wrapper">
                    <b-breadcrumb style="font-size: 0.9rem">
                        <b-breadcrumb-item to="/">
                        <feather-icon icon="HomeIcon" size="14" class="align-text-top" />
                        </b-breadcrumb-item>
                        <b-breadcrumb-item active>
                        CHANNELPARTNER
                        </b-breadcrumb-item>
                    </b-breadcrumb>
                    </div>
                </b-col>
                </b-row>
            </b-col>
        </div>
        <div>    
            <channelpartner :channelPartner="true"/>
        </div>
    </div>
</template>

<script>
import { BRow, BCol, BModal,  BBreadcrumb,
  BBreadcrumbItem } from 'bootstrap-vue'
import axios from '@/components/axios'
import kyc from '../../pages/profile/ProfileKyc.vue'
import channelpartner from '../../master/crm/channelpartner/ChannelPartner.vue'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    channelpartner,
    BModal,
    kyc,
    BRow,
    BCol
    // InvoiceList,
  },
  data () {
    return {
      accessToken: localStorage.getItem('accessToken'),
      baseApi: process.env.VUE_APP_APIENDPOINT

    }
  }

 
}
</script>
